<template>
  <div>
    <card title="Users">
        <template slot="header">
          <v-btn class="rounded-0" outlined dark small v-bind="attrs" v-on="on" @click="addDialog = true; addData.suspended = false"  >
            <v-icon>mdi-plus </v-icon> Add user
          </v-btn>

        </template>

        <div>
          <div width="150px" >
            <v-text-field class="mt-4" dense  append-icon="mdi-magnify" v-model="search" />
          </div>
            <br>

        <v-data-table dense how-select
            :loading="loading"
            :headers="headers"
            :items = "users"
            :search="search"
            v-model="selected"
            items-per-page="100"
            :footer-props="{ 'items-per-page-options': [50, 100, 150, -1] }"
        >

            <template v-slot:item.locations="{ item }"> {{  JSON.parse(item.locations).city  }} </template>

            <template v-slot:item.user_type="{ item }">


              <v-menu v-if="getPermission(['Admin'])"  offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn x-small rounded :color="getColor(item.user_type)"
                         dark v-bind="attrs" v-on="on"
                  ><span style="text-transform: initial; letter-spacing: 0">{{  getUserType(item.user_type) }}</span>

                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="(label, i) in userTypes" :key="i" @click="changeType(item,label.value)">
                    <v-list-item-title>{{ label.text }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-chip v-else :color="getColor(item.user_type)"  x-small dark>
                {{  getUserType(item.user_type) }}
              </v-chip>


            </template>

            <template v-slot:item.action="{ item }">

                <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                              <v-btn icon small v-bind="attrs" v-on="on" >
                                    <v-icon small>mdi-pencil</v-icon>
                              </v-btn>
                        </template>
                    <v-list dense>
                        <v-list-item v-for="(menuItem, i) in editActions" :key="i" @click="editButton(menuItem.action,item.uuid)">
                            <v-list-item-title class="font-weight-light">{{ menuItem.label }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>

    </template>



  </v-data-table>

          <v-dialog v-model="addDialog" width="500" class="rounded-0">
            <card title="Add new User">
              <form @submit.prevent="addRow" class="compact-form text-center" >


                <v-row align="center">
                  <v-col cols="3"><v-subheader>Firstname</v-subheader></v-col>
                  <v-col cols="9"> <v-text-field  v-model="addData.firstname"  label="Firstname" required /> </v-col>
                </v-row>
                <v-row align="center">
                  <v-col cols="3"><v-subheader>Lastname</v-subheader></v-col>
                  <v-col cols="9"> <v-text-field  v-model="addData.lastname"  label="Lastname" required /> </v-col>
                </v-row>
                <v-row align="center">
                  <v-col cols="3"><v-subheader>Email</v-subheader></v-col>
                  <v-col cols="9"> <v-text-field  v-model="addData.email"  label="Lastname" required /> </v-col>
                </v-row>
                <v-row align="center">
                  <v-col cols="3"><v-subheader>Password</v-subheader></v-col>
                  <v-col cols="9"> <v-text-field  v-model="addData.password"  label="Password" required /> </v-col>
                </v-row>
                <v-row align="center">
                  <v-col cols="3"><v-subheader>User Role</v-subheader></v-col>
                  <v-col cols="9">
                    <v-select :items="userTypes" v-model="addData.userType"  label="User Role" />
                  </v-col>
                </v-row>

                <v-row align="center">
                  <v-col cols="3"><v-subheader>Suspended</v-subheader></v-col>
                  <v-col cols="9">
                    <v-switch v-model="addData.suspended" label="Suspend user acount" ></v-switch>
                  </v-col>
                </v-row>








                <div class="d-flex justify-end">
                  <v-btn text class="rounded-0 mt-3" color="primary" :disabled="loading"   > Cancel </v-btn>
                  <v-btn text class="rounded-0 mt-3" type="submit" color="primary" :disabled="loading" @click="addDialog = false"  > Add </v-btn>
                </div>
              </form>
            </card>
          </v-dialog>

</div>




    </card>
  </div>
</template>

<script>
import {mapActions,mapState} from 'vuex';
import card from "@/components/pages/dashboard/UI/card";
export default {
  name: "DashUsers",
  components : {
    card
  },
  data() {
    return {
      selected:[],
      search : '',
      loading : true,
      addDialog : false,

      editActions : [
        {
          label: 'Edit Profile',
          action: 'edit'
        },

        {
          label: 'Delete user',
          action: 'delete'
        }
      ],
      addData : {
      },
      users : [
        { id: null ,
          firstname:null ,
          lastname: null ,
          email: null,
          locations: '{"city":""}',
          user_type: null ,
          last_access: null },
      ],
      headers: [
        { text: 'No', value: 'id' },
        { text: 'Firstname', value: 'firstname' },
        { text: 'Lastname', value: 'lastname' },
        { text: 'Email', value: 'email' },
        { text: 'City', value: 'locations' },
        { text: 'Type', value: 'user_type' },
        { text: 'Last Activity', value: 'last_access' },
        { text: '', value: 'action' },
      ],
    }
  },
  computed : {
    ...mapState('auth',['accessToken']),
    userTypes() {
      var types = []
      switch(this.$store.state.auth.userType) {
        case 'Admin' :
          types = [
            {text: 'Applicant', value : 1},
            {text: 'Student', value : 2},
            {text: 'Teacher', value : 3},
            {text: 'Manager', value : 4},
            {text: 'Partner', value : 6}
          ] ;
          break;

        case 'Partner' :
          types = [
            {text: 'Applicant', value : 1},
            {text: 'Partner', value : 6}
          ] ;
          break;
      }

    return types
    }
  },
  methods : {
    ...mapActions('dashboard',['GET_USERS','EDIT_USER_TYPE']),
    ...mapActions('settings',['ADD_USER']),
    getColor(type) {
      switch(type) {
        case 1 : return 'green lighten-2';
        case 2 : return 'success ';
        case 3 : return 'indigo lighten-2';
        case 5 : return 'primary';
        case 6 : return 'teal';
      }
       return 'accent'
    },
    getUserType(type){
      switch(type) {
        case 1 : return 'Applicant';
        case 2 : return 'Student';
        case 3 : return 'Teacher';
        case 5 : return 'Admin';
        case 6 : return 'Partner';
      }
    },
    editButton(action,item) {
      switch(action) {
        case 'edit' : this.$router.push({path:'/dashboard/profile/'+item}); break;
        case 'delete' : console.log('I will delete this user ! '+item); break;
      }
    },
    addRow() {
      this.loading = true;
      this.ADD_USER(this.addData)
          .then((out)=>{ this.loading = false; this.users.push(out.data); this.addData = {} })
          .catch(()=>{this.loading = false;})
    },
    changeType(user, type) {
      this.loading = true;
      this.EDIT_USER_TYPE({ token : this.accessToken, data : {uuid:user.uuid, user_type : type} })
          .then(()=>{
            user.user_type = type
            this.loading = false;})
          .catch(()=>{this.loading = false;})
    },
  },
  async mounted() {

    this.GET_USERS(this.accessToken).then((res)=>{
      this.users = res.data;
      this.loading = false
    })

}


}
</script>

<style scoped>

</style>