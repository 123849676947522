<template>
  <v-sheet elevation="1" style="overflow: hidden">
    <div v-if="!noTitle"
         class="header text-h6 d-flex justify-space-between align-center"
         :class="{noPadding : noPaddingTitle}"
        >
      <div v-if="title"> {{ title }} </div>
      <slot name="header"></slot>
    </div>
    <div class="content" :class="{noPadding: noPadding }">
      <slot></slot>
    </div>

  </v-sheet>
</template>

<script>
export default {
  name: "card",
  props : {
    title : String,
    noTitle : {type : Boolean, default : false },
    noPadding : Boolean,
    noPaddingTitle : {type : Boolean, default : false }
  }
}
</script>

<style scoped>
.header {
  background-color: #1976d1;
  color : white;
  padding : 10px 20px;
}
.content {
  padding: 10px 20px;
  background: white;
  overflow-y: hidden;
}
.noPadding  {
  padding : 0 !important
}
</style>